import React, { useContext } from "react"
import SEO from "../../components/seo"
import CustomAuth from "../../components/custom-auth"
import ResultEmailPreferences from "../../components/result-email-prefs"
import { UserContext } from "../../provider/usercontextprovider"
import { UserTenantProvider } from "../../provider/usertenantprovider"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isAdmin = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin');

  return (
        <CustomAuth>
            <SEO title="Preferences" />
            <UserTenantProvider>
                {isAdmin ? <ResultEmailPreferences userContext={userContext}/> : <p>User is not an admin</p>}
            </UserTenantProvider>
        </CustomAuth>
  )

}
export default ProtectedPage
