import React, { useEffect, useState, useContext } from "react"
import { API, graphqlOperation } from "aws-amplify"
import * as badgeStyles from "../styles/badge.module.css";
import * as formElementStyles from "../styles/form-elements.module.css";
import { LoadMaskContext } from "../provider/loadmaskprovider";
import { Notification, NotificationType } from "./notification";
import { UserTenantContext } from "../provider/usertenantprovider";
import Select from "react-select";

const getTenantConfigs = `
query ListAnalyticConfigurations(
$tenant: ID
$filter: ModelAnalyticConfigurationFilterInput
$limit: Int
$nextToken: String
$sortDirection: ModelSortDirection
) {
listAnalyticConfigurations(
    tenant: $tenant
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
) {
    items {
        tenant
        questionConfiguration {
            id
            weight
            weightTrigger
        }
        resultConfiguration {
            id
            threshold
            emailAddressesToAlert
        }
        adminGroup
        tenantObj {
            name
            description
            prettyName
            codeTranslations {
                items {
                    tenant
                    language
                    analyticResults {
                        id
                        answer
                        proceed
                    }
                }
                nextToken
                }
            }
        }
        nextToken
    }
}`

const mutation = `
mutation UpdateAnalyticConfiguration(
    $input: UpdateAnalyticConfigurationInput!
    $condition: ModelAnalyticConfigurationConditionInput
) {
updateAnalyticConfiguration(input: $input, condition: $condition) {
    tenant
    questionConfiguration {
        id
        weight
        weightTrigger
    }
    resultConfiguration {
        id
        threshold
        emailAddressesToAlert
    }
    adminGroup
    tenantObj {
        name
        description
        prettyName
        codeTranslations {
            items {
                tenant
                language
                analyticResults {
                    id
                    answer
                    proceed
                }
            }
            nextToken
        }
    }
}
}
`

// Unsubscribe is done by per email basis with unauthed user

const ResultEmailPreferences = props => {
  const [analyticConfigs, setAnalyticConfigs] = useState([]);
  const [isLoading, setIsLoading] = useContext(LoadMaskContext);
  const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
  // This should probably be a global state
  const [language, setLanguage] = useState("eng");
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);
  const [analyticResultConfig, setAnalyticResultConfig] = useState(null);

  useEffect(() => {
    getResultConfigurations()
  }, [])

  const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
    return {
        value: tenant.tenantObj.name,
        label: tenant.tenantObj.prettyName,
    }
  });

  const onTenantChange = e => {
    let config = analyticConfigs.find(config => config.tenant === e.value);
    setAnalyticResultConfig(config);
  }

  const getResultConfigurations = async () => {
    setIsLoading(true);
    const analyticConfigsGql = await API.graphql(
      graphqlOperation(getTenantConfigs)
    )
    const configs = analyticConfigsGql.data.listAnalyticConfigurations.items
    setAnalyticConfigs(configs)
    setIsLoading(false);
  }

  const validateEmail = (email) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        return true
    }
    return false
  }

  const addIfEnter = (resultCode,tenantName) => event => {
    setShowInvalidEmailMessage(false);
    if (event.key === "Enter" && !event.target.validity.typeMismatch && event.target.value.length) {
        if (validateEmail(event.target.value) === true) {
            const tenantIdx = analyticConfigs.findIndex(currentConfig => currentConfig.tenant === tenantName);
            const resultCodeIdx = analyticConfigs[tenantIdx].resultConfiguration.findIndex(x => x.id === resultCode);
            let copyOfAlteredObject = Object.assign({},analyticConfigs[tenantIdx]);
            if(copyOfAlteredObject.resultConfiguration[resultCodeIdx].emailAddressesToAlert){
                copyOfAlteredObject.resultConfiguration[resultCodeIdx].emailAddressesToAlert.push(event.target.value);
            }else{
                copyOfAlteredObject.resultConfiguration[resultCodeIdx].emailAddressesToAlert = [event.target.value]
            }
            event.target.value = "";
            updateEmails(copyOfAlteredObject,tenantIdx)
        } else {
            setShowInvalidEmailMessage(true);
        }
      
    }
  }

  const updateEmails = async (config,tenantIdxToUpdate) => {
    // We have to delete the tenantObj as it's not part of the input mutation
    delete config.tenantObj;
    setIsLoading(true)
    const graphqlResults = await API.graphql(
      graphqlOperation(mutation,{input: config})
    );
    const configList = [...analyticConfigs];
    configList[tenantIdxToUpdate] = graphqlResults.data.updateAnalyticConfiguration;
    setAnalyticConfigs(configList);
    setAnalyticResultConfig(configList[tenantIdxToUpdate]);
    setIsLoading(false)
  }

  const removeEmail = (listItem,resultCode,tenantName) => () => {
    const tenantIdx = analyticConfigs.findIndex(currentConfig => currentConfig.tenant === tenantName);
    const resultCodeIdx = analyticConfigs[tenantIdx].resultConfiguration.findIndex(x => x.id === resultCode);
    let emailArray = analyticConfigs[tenantIdx].resultConfiguration[resultCodeIdx].emailAddressesToAlert;
    const i = emailArray.findIndex(x => x === listItem);
    let copyOfAlteredObject = Object.assign({},analyticConfigs[tenantIdx]);
    copyOfAlteredObject.resultConfiguration[resultCodeIdx].emailAddressesToAlert.splice(i, 1);
    updateEmails(copyOfAlteredObject,tenantIdx);
  }

  return (
    <>
      <h1>Email Configuration</h1>
      <div className={formElementStyles.form} style={{ marginBottom: "1rem" }}>
          <div className={formElementStyles.inputWrapper}>
              <label className={formElementStyles.label}>
                  Tenant
              </label>
              <Select options={options} onChange={onTenantChange} />
          </div>
      </div>
      {showInvalidEmailMessage &&
        <Notification messageType={NotificationType.FAIL}>
            <p>Not a valid email</p>
        </Notification>
      }
      {analyticResultConfig &&
        <div key={analyticResultConfig.tenant}>
            {analyticResultConfig.resultConfiguration.map(resultConf => (
            <div style={{ marginTop: "2rem"}} key={resultConf.id}>
                <span>{resultConf.id} - </span>
                <span style={{fontStyle:"italic"}}>
                {analyticResultConfig.tenantObj.codeTranslations.items.find(x=>x.language === language && x.tenant === analyticResultConfig.tenant).analyticResults.find(x=>x.id === resultConf.id).answer}
                </span>
                <div style={{ marginTop: "0.5rem" }}>
                    <div className={formElementStyles.multiSelectWrapper}>
                        {resultConf.emailAddressesToAlert && resultConf.emailAddressesToAlert.map((listItem, index) => (
                            <div style={{ marginRight: "0.375rem", padding: "0" }} key={index}>
                                <span className={`${badgeStyles.badge} ${badgeStyles.badgeWithButton}`}>
                                    {listItem}
                                    <button type="button" className={badgeStyles.removeButton} onClick={removeEmail(listItem,resultConf.id,analyticResultConfig.tenant)}>
                                        <svg style={{ height: "1rem", width: "1rem" }} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </span>
                            </div>
                        ))}
                        <input type="text" className={formElementStyles.multiSelectInput} onKeyDown={addIfEnter(resultConf.id,analyticResultConfig.tenant)} />
                    </div>
                </div>
            </div>
            ))}
        </div>
      }
    </>
  )
}

export default ResultEmailPreferences
